// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const QUERY = gql`
  query DossierHeader(
    $dossierId: ID!,
    $dossierTypeSlug: String!,
    $navigationSlug: String!,
    $blockId: ID,
    $context: String,
  ) {
    header: dossierHeaderBlockById(
      id: $dossierId,
      dossierTypeSlug: $dossierTypeSlug,
      navigationSlug: $navigationSlug,
      blockId: $blockId,
      context: $context
    ) {
      title
      subTitle
      status {
        label
      }
      tags {
        id
        label
        label_color
        label_text_color
      }
      assigned {
        id
        label
        label_color
        label_text_color
        users {
          id
          username
        }

      }
      metadata {
        ... on TagMetadata {
          tag_create_label
          apply_tag_to_dossier
        }
        ... on AssignmentMetadata {
          assign_self
          assignable {
            id
            label
            label_color
            label_text_color
          }
        }
        ... on GroupMetadata {
          groupTypes {
            id
            label
            label_color
            label_text_color
            settings
            groups {
              id
              name
            }
          }
        }
      }
    }
    workflowTransitionButtons: dossierWorkflowTransitionsById(
      id: $dossierId
      dossierTypeSlug: $dossierTypeSlug,
      navigationSlug: $navigationSlug
    ) {
      name
      label
      workflowId
      successMessage
      errorMessage
      metadata
    }
    contextMenu: dossierContextMenuById(
      id: $dossierId
      dossierTypeSlug: $dossierTypeSlug,
      navigationSlug: $navigationSlug
    ) {
      items {
        label
        url
        metadata
      }
    }
  }
`;

export default QUERY;
